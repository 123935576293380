import { usersTypes } from "../actions/login.action";
import { createSelector } from "reselect";

const initialState = {
  usuarioSeleccionado: null,
  loading: false,
  error: null,
};

const { USERS_REQUEST, USERS_SUCCESS, USERS_ERROR,USERS_LOGOUT} = usersTypes;

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_REQUEST:
      return { ...state, loading: true };
    case USERS_SUCCESS:
      return { ...state, loading: false, usuarioSeleccionado: action.payload, error: null };
    case USERS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case USERS_LOGOUT:
      return { ...state, loading: false, error: null, usuarioSeleccionado: null }
    default:
      return state;
  }
}

// Usuario
const usuariosItemsSelector = state => state.login;
const resultFnUsuarioSeleccionado = items => {
  return items.usuarioSeleccionado;
};
const selectUsuarioSeleccionado = createSelector(
  usuariosItemsSelector,
  resultFnUsuarioSeleccionado
);

// Loading
const selectUsuariosLoading = state => {
  return state.login.loading
};

// function selectUsuariosLoading(state) {
//   return state.login.loading;
// }

// Error
function selectUsuariosError(state) {
  return state.login.error;
}

export default loginReducer;

export {
  selectUsuarioSeleccionado,
  selectUsuariosLoading,
  selectUsuariosError
}; 