import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    selectUsuarioSeleccionado,
    selectUsuariosLoading,
    selectUsuariosError
} from "../redux/reducer/loginReducer";
import { loginUser } from "../redux/actions/login.action";
import { ToastContainer, toast } from 'react-toastify';

const useLogin = (label, stateInicial) => {

    const MESSAGE_CORREO_VALIDO = "Introduce un correo electrónico válido";
    const MESSAGE_PASSWORD_VALIDO = "El password tiene que tener al menos 6 carácteres";

    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [emailEsValido, setEmailEsValido] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordEsValido, setPasswordEsValido] = useState(false);

    const dispatch = useDispatch();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const loadingUsuario = useSelector(state => {
        return selectUsuariosLoading(state);
    });
    const error = useSelector(selectUsuariosError);
    const [enviar, setEnviar] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Validación del correo electronico
        if (email === null || email === "") {
            setEmailMessage("");
            setEmailEsValido(false);
        } else {
            const atposition = email.indexOf("@");
            const dotposition = email.lastIndexOf(".");
            const spaceposition = email.indexOf(" ");
            if (spaceposition !== -1 || atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= email.length) {
                setEmailMessage(MESSAGE_CORREO_VALIDO);
                setEmailEsValido(false);
            } else {
                setEmailMessage("");
                setEmailEsValido(true);
            }
        }

        // Validación del password
        if (password === null || password === "") {
            setPasswordMessage("");
            setPasswordEsValido(false);
        } else {
            if (password.length < 6) {
                setPasswordMessage(MESSAGE_PASSWORD_VALIDO);
                setPasswordEsValido(false);
            } else {
                setPasswordMessage("");
                setPasswordEsValido(true);
            }
        }

        setEnviar(false);

    }, [email, password]);

    useEffect(() => {

        if (emailEsValido && passwordEsValido) {
            setLoading(true);
            dispatch(loginUser(email, password));
            setLoading(false);
        }

    }, [enviar]);
    useEffect(() => {

        enviar && error && notify();

    }, [enviar, error]);

    const notify = () => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setEnviar(false);
    }

    useEffect(() => {

        console.log("Usuario seleccionado")
        console.log(usuarioSeleccionado)

    }, [usuarioSeleccionado]);

    // Return el state, interfaz y funcion que modifica el state
    return [
        setEmail,
        emailEsValido,
        emailMessage,
        passwordEsValido,
        setPassword,
        setEnviar,
        error,
        usuarioSeleccionado,
    ];
}

export default useLogin;