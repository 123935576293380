import TextField from '@material-ui/core/TextField';

const InputText = (props) => {
    const { name, label, required, value, onChange, errorMessage, esValido, type, autoComplete } = props;
    return (
        <>
            <div className="form-group">
                <TextField
                    type={type}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id={name}
                    label={label}
                    name={name}
                    autoComplete={autoComplete}
                    autoFocus
                    onChange={onChange}
                    value={value}
                />
                {(!esValido && errorMessage ? <small id="emailHelp" className="form-text text-danger">{errorMessage}</small> : <></>)}
                {(esValido ? <small id="emailHelp" className="form-text text-success">Correcto</small> : <></>)}
            </div>
        </>
    )
};

export default InputText;